<template>
  <div :key="idCourse">
    <div v-if="courseData" class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <h2 class="mr-3">{{ courseData.name }}</h2>
        <!-- <a-tag :color="courseData.statusPublication === 'published' ? 'green' : 'orange'">{{courseData.statusPublication === 'published' ? 'TERPUBLIKASI': 'DRAF'}}</a-tag> -->
      </div>
      <div v-if="store.state.user.role === 'admin'">
        <a-button @click.prevent="deleteCourse" type="danger">
          <delete-outlined></delete-outlined>
          Hapus Potensi</a-button>
      </div>
    </div>
    <a-card>
      <div>
        <a-row class="mt-4" :gutter="[32, 0]">
          <a-col :md="24">
            <div class="text-center">
              <a-radio-group class="mb-5" v-model:value="activeMenu" button-style="solid">
                <a-radio-button value="general">{{ title }}</a-radio-button>
              </a-radio-group>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="row">
        <!-- <div class="col-xl-3 mb-3" v-for="(i, idx) in 10" :key="i">
          <a-card>
            <div>
              <span>
                <div><small><b>Maintenance Card {{ i }}</b></small> </div>
                <span v-if="idx % 4 == 1"> Bersdxh & tidak berdebu </span>
                <span v-if="idx % 4 == 2"> Rak dalam kondisi baik </span>
                <span v-if="idx % 4 == 3"> Pintu Rak tertutup & terkunci </span>
                <span v-if="idx % 4 == 0"> Kipas atas dalam kondisi baik </span>
              </span>
              <div class="border-top mt-3 mb-3"></div>
              <div class="row">
                <span class="col-6">
                  <a v-if="i < 4" href="" class="btn btn-primary w-100"> Ya </a>
                  <a v-if="i >= 4" href="" class="btn btn-clear w-100"> Ya </a>
                </span>
                <span class="col-6">
                  <a href="" class="btn btn-clear w-100"> Tidak </a>
                </span>
              </div>
            </div>
          </a-card>
        </div> -->
        <div class="col-xl-3 mb-3" v-for="(i, idx) in maintenanceData" :key="i">
          <a-card>
            <div>
              <span>
                <div><small><b>Question Card {{ idx + 1 }}</b></small> </div>
                <span> {{ i.SOP.questionDesc }}</span>

              </span>
              <div class="border-top mt-3 mb-3"></div>
              <div class="row" v-if="i.SOP.answerType === 'yes_no'">
                <span class="col-6">
                  <a :class="{
                    'btn-primary': i.MaintenanceAnswer.answer === 'yes' ? true : false,
                    'btn-clear': i.MaintenanceAnswer.answer !== 'yes' ? true : false,
                    'btn': true,
                    'w-100': true
                  }" @click="() => inputAnswers(i.SOP.answerContent ? i.SOP.answerContent.yes : 'yes', 'yes_no', i)"
                    v-if="i.MaintenanceAnswer"> {{
                      i.SOP.answerContent ? i.SOP.answerContent.yes : 'Ya' }} </a>
                  <a class="btn btn-clear w-100"
                    @click="() => inputAnswers(i.SOP.answerContent ? i.SOP.answerContent.yes : 'yes', 'yes_no', i)"
                    v-else> {{
                      i.SOP.answerContent ? i.SOP.answerContent.yes : 'Ya' }} </a>
                </span>
                <span class="col-6">
                  <a :class="{
                    'btn-primary': i.MaintenanceAnswer.answer === 'no' ? true : false,
                    'btn-clear': i.MaintenanceAnswer.answer !== 'no' ? true : false,
                    'btn': true,
                    'w-100': true
                  }" @click="() => inputAnswers(i.SOP.answerContent ? i.SOP.answerContent.no : 'no', 'yes_no', i)"
                    v-if="i.MaintenanceAnswer"> {{
                      i.SOP.answerContent ? i.SOP.answerContent.no : 'Tidak' }} </a>
                  <a class="btn btn-clear w-100"
                    @click="() => inputAnswers(i.SOP.answerContent ? i.SOP.answerContent.no : 'no', 'yes_no', i)" v-else>
                    {{
                      i.SOP.answerContent ? i.SOP.answerContent.no : 'Tidak' }} </a>
                </span>
              </div>
              <div class="row" v-if="i.SOP.answerType === 'text_area'">
                <span class="col-12">
                  <a-textarea placeholder="Isi keterangan..."
                    :default-value="i.MaintenanceAnswer ? i.MaintenanceAnswer.answer : ''" allow-clear
                    :auto-size="{ minRows: 2, maxRows: 6 }" @change="e => inputAnswers(e.target.value, 'text_area', i)" />
                </span>
              </div>
              <div class="row" v-if="i.SOP.answerType == 'number'">
                <span class="col-12">
                  <a-input type="number" :min="1" :max="100000"
                    :default-value="i.MaintenanceAnswer ? i.MaintenanceAnswer.answer : ''"
                    @change="e => inputAnswers(e.target.value, 'number', i)" />
                </span>
              </div>
              <div class="row" v-if="i.SOP.answerType == 'select'">
                <span class="col-12">
                  <a-select ref="select" class="w-100" @change="value => inputAnswers(value, 'select', i)"
                    :default-value="i.MaintenanceAnswer ? i.MaintenanceAnswer.answer : ''" placeholder="Pilih jawaban...">
                    <a-select-option :value="item" v-for="(item, key) in i.SOP.answerContent " :key="key">{{ item
                    }}</a-select-option>
                  </a-select>
                </span>
              </div>
            </div>
          </a-card>
        </div>
      </div>
      <div class="w-100 text-center">
        <a-button type="primary" size="large" class=" mt-3" @click="submitAnswers()"
          :disabled="answers.length ? false : true">Submit
          Answers</a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
import { onMounted, ref, createVNode, computed } from 'vue'
import { defineAsyncComponent, h } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined, SmileOutlined, CheckCircleOutlined,
} from '@ant-design/icons-vue'
import { message, Modal, notification } from 'ant-design-vue'
import { getOne, remove, getAllPendings, update } from '@/services/axios/brivice-backend'
import apiClient from '@/services/axios'
import {
  generateTask, getTaskByDate, getTaskByDetail, postAnswers,
} from '../../../services/axios/modc/eos'
export default {
  components: { DeleteOutlined },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const maintenanceData = ref([])
    const answers = ref([])
    const title = ref('')


    const inputAnswers = async (value, type, record) => {
      if (record.MaintenanceAnswer) {
        record.MaintenanceAnswer.answer = value
      } else {
        record.MaintenanceAnswer = {
          answer: value,
        }
      }
      const findAnswer = answers.value.find(e => e.maintenanceQuestionId === record.id)
      if (findAnswer) {
        findAnswer.answer = value
      } else {
        answers.value.push({
          id: record.MaintenanceAnswer.id ? record.MaintenanceAnswer.id : null,
          maintenanceQuestionId: record.id,
          answer: value,
        })
      }
      if (type === 'yes_no') {
        notification.open({
          message: 'Success Update Answer',
          description:
            'Dont forget to submit so that your answers are not lost and are stored properly',
          icon: h(ExclamationCircleOutlined, {
            style: 'color: #FFB534',
          }),
        });
      }

    }
    const submitAnswers = async () => {
      // console.log(answers.value)
      const data = await postAnswers(answers.value)
      if (data) {
        notification.success({
          message: 'Berhasil Submit Jawaban',
          description: 'Jawaban sudah disimpan dengan baik',
        })
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Internal Server Error',
        })
      }
      router.push('/monitoring')
    }
    const fetchData = async () => {
      const { taskDetail } = await getTaskByDetail(+route.params.id)
      const questions = taskDetail.MaintenanceQuestions
      maintenanceData.value = questions
      title.value = taskDetail.Asset.name
    }
    onMounted(async () => {
      await fetchData()
    })

    return { route, maintenanceData, inputAnswers, submitAnswers, fetchData, answers, title }
  },
}
</script>
<style lang="scss">
.th-upload-wrapper {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;

    .ant-upload {
      width: 300px;
      height: 100px;
    }
  }
}
</style>
